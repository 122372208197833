import React from 'react';
import Helmet from '../project/Helmet';
import {t} from '@lingui/macro';
import CompanyDocument from './CompanyDocument';
import { createFetchCompanyEditReturnConditions, createFetchCompanyReturnConditions } from '../../backend/apiCalls';

/**
 * @fero
 */

class ClaimRulesPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`Reklamačný poriadok`}
            />
            <CompanyDocument
                loadFcn={createFetchCompanyReturnConditions()}
                saveFcn={createFetchCompanyEditReturnConditions()}
            />
        </React.Fragment>;
    }

}

export default ClaimRulesPage;